import React, { useEffect } from "react"
import styles from "./skg-hero-anim.module.scss"
import anime from "animejs"

const SkgHeroAnim = () => {
  const totalDuration = 2000
  const inputDuration = 4000
  const inputMidDuration = 6000
  const outputNDuration = 6000
  const midDuration = 2000
  const outputDuration = 4000

  let maintl
  let inputNTr
  let inputSTr
  let inputMidTr
  let midTr
  let outputNTr
  let outputTr

  useEffect(() => {
    maintl = anime.timeline({
      easing: "easeInOutQuad",
      duration: totalDuration,
      autoplay: true,
    })

    inputNTr = anime.timeline({
      easing: "linear",
      duration: inputDuration,
      autoplay: true,
      loop: true,
    })

    inputSTr = anime.timeline({
      easing: "linear",
      duration: inputDuration,
      autoplay: true,
      loop: true,
    })

    inputMidTr = anime.timeline({
      easing: "linear",
      duration: inputMidDuration,
      autoplay: true,
      loop: true,
    })

    outputNTr = anime.timeline({
      easing: "linear",
      duration: outputNDuration,
      autoplay: true,
      loop: true,
    })

    midTr = anime.timeline({
      easing: "linear",
      duration: outputNDuration,
      autoplay: true,
      loop: true,
    })

    outputTr = anime.timeline({
      easing: "linear",
      duration: outputNDuration,
      autoplay: true,
      loop: true,
    })

    maintl
      .add({
        targets: "#img",
        duration: 900,
        opacity: [0, 1],
        translateY: [-8, 1],
      })
      .add({
        targets: "#svg",
        duration: 900,
        opacity: [0, 1],
      })

    inputNTr
      .add({
        targets: "#bars-input-n1 g",
        duration: inputDuration,
        translateX: [91, 176],
        translateY: [0, -50],
      })
      .add({
        targets: "#bars-input-n2 g",
        duration: inputDuration,
        translateX: [0, 91],
        translateY: [49.5, 0],
        delay: -inputDuration,
      })

    inputSTr
      .add({
        targets: "#bars-input-s1 g",
        duration: inputDuration,
        translateX: [-3, 67],
        translateY: [-20.624756, -85.375244],
      })
      .add({
        targets: "#bars-input-s2 g",
        duration: inputDuration,
        translateX: [-94, -3],
        translateY: [22.875224, -26.624756],
        delay: -inputDuration,
      })

    inputMidTr
      .add({
        targets: "#bars-input-m1 g",
        duration: inputMidDuration,
        translateX: [-25, 194],
        translateY: [-15, -140],
      })
      .add({
        targets: "#bars-input-m2 g",
        duration: inputMidDuration,
        translateX: [-251, -25],
        translateY: [107, -15],
        delay: -inputMidDuration,
      })

    midTr
      .add({
        targets: "#bars-mid-s1 g",
        duration: outputNDuration,
        translateX: [-55, 15],
        translateY: [-9, -57],
      })
      .add({
        targets: "#bars-mid-s2 g",
        duration: outputNDuration,
        translateX: [-146, -55],
        translateY: [40.5, -9],
        delay: -outputNDuration,
      })

    outputNTr
      .add({
        targets: "#bars-output-n1 g",
        duration: outputNDuration,
        translateX: [-25, 218],
        translateY: [-11, -121],
      })
      .add({
        targets: "#bars-output-n2 g",
        duration: outputNDuration,
        translateX: [-251, -30],
        translateY: [111, -11],
        delay: -outputNDuration,
      })

    outputTr
      .add({
        targets: "#bars-output-m1 g",
        duration: outputDuration,
        translateX: [91, 181],
        translateY: [0, -52],
      })
      .add({
        targets: "#bars-output-m2 g",
        duration: outputDuration,
        translateX: [0, 91],
        translateY: [49.5, 0],
        delay: -outputDuration,
      })
      .add({
        targets: "#bars-output-s1 g",
        duration: outputDuration,
        translateX: [-3.0, 108],
        translateY: [-26.624756, -65],
        delay: -outputDuration,
      })
      .add({
        targets: "#bars-output-s2 g",
        duration: outputDuration,
        translateX: [-94.0, -3.0],
        translateY: [22.875244, -26.624756],
        delay: -outputDuration,
      })
  })

  return (
    <div className={styles.vsanim}>
      <svg
        width="1029px"
        height="591px"
        viewBox="0 0 1029 591"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        id="svg">
        <defs>
          <polygon
            id="path-1"
            points="205.56252 0.819383571 226.294624 13.8694204 22.7168445 127.661908 5.13280814e-15 115.112179"></polygon>
          <polygon
            id="path-3"
            points="214.236405 0.88872758 234.968509 13.9387644 22.7168445 131.661908 5.13280814e-15 119.112179"></polygon>
          <path
            d="M128.669189,0 L146.985045,11.03922 L116.0611,29.7269223 C100.863965,39.5604204 85.8899246,40.2629846 71.1389794,31.8346149 C64.0497036,27.7839567 59.4358438,24.8884224 56.0113335,23.0468504 C48.7475665,19.1406715 46.6668643,19.7914924 37.4676335,24.2631796 C29.3639129,28.2023463 23.208584,31.1070824 19.0016468,32.9773878 L0,21.2139031 L34.7297708,4.70055782 C45.5244874,1.51742821 54.7192364,1.75809751 62.3140177,5.42256574 C69.9087989,9.08703396 77.2734425,12.8311769 84.4079483,16.6549946 C90.1963798,20.3913587 97.6522328,19.3330436 106.775507,13.4800493 C115.898782,7.62705498 123.196676,3.13370521 128.669189,0 Z"
            id="path-5"></path>
          <path
            d="M128.669189,0 L146.985045,11.03922 L116.0611,29.7269223 C100.863965,39.5604204 85.8899246,40.2629846 71.1389794,31.8346149 C64.0497036,27.7839567 59.4358438,24.8884224 56.0113335,23.0468504 C48.7475665,19.1406715 46.6668643,19.7914924 37.4676335,24.2631796 C29.3639129,28.2023463 23.208584,31.1070824 19.0016468,32.9773878 L0,21.2139031 L34.7297708,4.70055782 C45.5244874,1.51742821 54.7192364,1.75809751 62.3140177,5.42256574 C69.9087989,9.08703396 77.2734425,12.8311769 84.4079483,16.6549946 C90.1963798,20.3913587 97.6522328,19.3330436 106.775507,13.4800493 C115.898782,7.62705498 123.196676,3.13370521 128.669189,0 Z"
            id="path-7"></path>
          <path
            d="M143,78.0189708 L162.464451,88.7694946 C170.223597,84.8856 174.103169,82.9436528 174.103169,82.9436528 C186,76.1818182 187.011781,68.0843867 187.037076,63.0794374 C187.102553,50.12365 177.660871,42.1385114 173,39.6904566 C166.538588,36.2966937 166.318622,33.6679287 172.340103,31.8041616 L203,14.4056019 L183.670051,4 L159.370678,16.9456552 C149.247032,22.2908205 145.07557,30.2674824 146.856292,40.875641 C147.578668,45.1789897 149.840173,46.7589718 152.274713,48.5821421 C153.540355,49.5299519 157.782117,52.1194218 165,56.350552 C170.102792,60.2211116 169.257609,63.972933 162.464451,67.6060162 C155.671292,71.2390994 149.183142,74.7100843 143,78.0189708 Z"
            id="path-9"></path>
          <path
            d="M143,78.0189708 L162.464451,88.7694946 C170.223597,84.8856 174.103169,82.9436528 174.103169,82.9436528 C186,76.1818182 187.011781,68.0843867 187.037076,63.0794374 C187.102553,50.12365 177.660871,42.1385114 173,39.6904566 C166.538588,36.2966937 166.318622,33.6679287 172.340103,31.8041616 L203,14.4056019 L183.670051,4 L159.370678,16.9456552 C149.247032,22.2908205 145.07557,30.2674824 146.856292,40.875641 C147.578668,45.1789897 149.840173,46.7589718 152.274713,48.5821421 C153.540355,49.5299519 157.782117,52.1194218 165,56.350552 C170.102792,60.2211116 169.257609,63.972933 162.464451,67.6060162 C155.671292,71.2390994 149.183142,74.7100843 143,78.0189708 Z"
            id="path-11"></path>
          <path
            d="M35.9566055,67.8228602 C40.5044785,62.739341 41.019127,57.6308505 41.0370755,54.0794374 C41.102553,41.12365 31.660871,33.1385114 27,30.6904566 C20.5385882,27.2966937 20.3186224,24.6679287 26.3401025,22.8041616 L46.9189672,10.8752439 L27.5890185,0.469642033 L13.3706779,7.9456552 C3.24703152,13.2908205 -0.924430335,21.2674824 0.856292316,31.875641 C1.5786675,36.1789897 3.84017264,37.7589718 6.27471301,39.5821421 C7.54035516,40.5299519 11.7821175,43.1194218 19,47.350552 C23.1964157,50.8924245 22.9800088,54.2178143 18.3507792,57.3267215 C18.3507792,57.3267215 35.9566055,68 35.9566055,67.8228602 Z"
            id="path-13"></path>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd">
          <g id="scg" transform="translate(-252.000000, -1000.000000)">
            <g id="Group-4-Copy" transform="translate(252.000000, 1000.000000)">
              <path
                d="M613.43595,0.603577707 C619.783182,0.603577707 626.133649,1.99973195 630.977574,4.81228802 L630.977574,4.81228802 L1021.33526,226.791663 C1025.9946,229.497562 1028.39642,233.019595 1028.39642,236.581628 C1028.39642,240.14361 1025.99463,243.664921 1021.3383,246.367779 L1021.3383,246.367779 L433.900791,582.971959 C424.389328,588.496523 417.385789,590.763264 410.504306,590.348578 C402.845298,589.887036 395.353507,586.124393 384.763004,579.973096 L384.763004,579.973096 L7.66263188,363.215184 C3.00466022,360.510626 0.603577707,356.989283 0.603577707,353.427307 C0.603577707,349.865278 3.00468967,346.343214 7.66082147,343.638415 L7.66082147,343.638415 L595.899042,4.80957855 C600.738252,1.99973195 607.088719,0.603577707 613.43595,0.603577707 Z"
                id="--copy"
                strokeOpacity="0"
                stroke="#000000"
                strokeWidth="1.20715541"
                opacity="0.300000012"></path>
              <g
                id="input-m"
                opacity="0.5"
                transform="translate(235.000000, 299.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <use
                  id="input-m2-mask"
                  fillOpacity="0.08"
                  fill="#FFFFFF"
                  xlinkHref="#path-1"></use>
                <g id="bars-input-m1" mask="url(#mask-2)" fill="#DE7373">
                  <g transform="translate(-25.000000, -15.000000)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="215.294624 4.25117832 267.417874 33.3267215 279.350779 30.699592 223.656616 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="158.294624 34.2511783 210.417874 64.3267215 222.350779 60.699592 166.656616 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="169.294624 28.2511783 221.417874 57.3267215 233.350779 54.699592 177.656616 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="180.294624 22.2511783 232.417874 51.3267215 244.350779 48.699592 188.656616 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="192.294624 16.2511783 244.417874 45.3267215 256.350779 42.699592 200.656616 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="203.294624 10.2511783 255.417874 39.3267215 267.350779 36.699592 211.656616 6"></polygon>
                    <polygon
                      id="Path-4-Copy-45"
                      points="147 41.2511783 199.123251 70.3267215 211.056156 67.699592 155.361993 37"></polygon>
                    <polygon
                      id="Path-4-Copy-44"
                      points="90 71.2511783 142.123251 101.326721 154.056156 97.699592 98.3619927 67"></polygon>
                    <polygon
                      id="Path-4-Copy-43"
                      points="101 65.2511783 153.123251 94.3267215 165.056156 91.699592 109.361993 61"></polygon>
                    <polygon
                      id="Path-4-Copy-42"
                      points="112 59.2511783 164.123251 88.3267215 176.056156 85.699592 120.361993 55"></polygon>
                    <polygon
                      id="Path-4-Copy-41"
                      points="124 53.2511783 176.123251 82.3267215 188.056156 79.699592 132.361993 49"></polygon>
                    <polygon
                      id="Path-4-Copy-40"
                      points="135 47.2511783 187.123251 76.3267215 199.056156 73.699592 143.361993 43"></polygon>
                    <polygon
                      id="Path-4-Copy-51"
                      points="79 78.2511783 131.123251 107.326721 143.056156 104.699592 87.3619927 74"></polygon>
                    <polygon
                      id="Path-4-Copy-50"
                      points="22 108.251178 74.1232505 138.326721 86.0561555 134.699592 30.3619927 104"></polygon>
                    <polygon
                      id="Path-4-Copy-49"
                      points="33 102.251178 85.1232505 131.326721 97.0561555 128.699592 41.3619927 98"></polygon>
                    <polygon
                      id="Path-4-Copy-53"
                      points="-1.0382307e-12 120.251178 52.1232505 150.326721 64.0561555 146.699592 8.3619927 116"></polygon>
                    <polygon
                      id="Path-4-Copy-52"
                      points="11 114.251178 63.1232505 143.326721 75.0561555 140.699592 19.3619927 110"></polygon>
                    <polygon
                      id="Path-4-Copy-48"
                      points="44 96.2511783 96.1232505 125.326721 108.056156 122.699592 52.3619927 92"></polygon>
                    <polygon
                      id="Path-4-Copy-47"
                      points="56 90.2511783 108.123251 119.326721 120.056156 116.699592 64.3619927 86"></polygon>
                    <polygon
                      id="Path-4-Copy-46"
                      points="67 84.2511783 119.123251 113.326721 131.056156 110.699592 75.3619927 80"></polygon>
                  </g>
                </g>
                <g id="bars-input-m2" mask="url(#mask-2)" fill="#DE7373">
                  <g transform="translate(-251.000000, 107.000000)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="215.294624 4.25117832 267.417874 33.3267215 279.350779 30.699592 223.656616 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="158.294624 34.2511783 210.417874 64.3267215 222.350779 60.699592 166.656616 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="169.294624 28.2511783 221.417874 57.3267215 233.350779 54.699592 177.656616 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="180.294624 22.2511783 232.417874 51.3267215 244.350779 48.699592 188.656616 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="192.294624 16.2511783 244.417874 45.3267215 256.350779 42.699592 200.656616 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="203.294624 10.2511783 255.417874 39.3267215 267.350779 36.699592 211.656616 6"></polygon>
                    <polygon
                      id="Path-4-Copy-45"
                      points="147 41.2511783 199.123251 70.3267215 211.056156 67.699592 155.361993 37"></polygon>
                    <polygon
                      id="Path-4-Copy-44"
                      points="90 71.2511783 142.123251 101.326721 154.056156 97.699592 98.3619927 67"></polygon>
                    <polygon
                      id="Path-4-Copy-43"
                      points="101 65.2511783 153.123251 94.3267215 165.056156 91.699592 109.361993 61"></polygon>
                    <polygon
                      id="Path-4-Copy-42"
                      points="112 59.2511783 164.123251 88.3267215 176.056156 85.699592 120.361993 55"></polygon>
                    <polygon
                      id="Path-4-Copy-41"
                      points="124 53.2511783 176.123251 82.3267215 188.056156 79.699592 132.361993 49"></polygon>
                    <polygon
                      id="Path-4-Copy-40"
                      points="135 47.2511783 187.123251 76.3267215 199.056156 73.699592 143.361993 43"></polygon>
                    <polygon
                      id="Path-4-Copy-51"
                      points="79 78.2511783 131.123251 107.326721 143.056156 104.699592 87.3619927 74"></polygon>
                    <polygon
                      id="Path-4-Copy-50"
                      points="22 108.251178 74.1232505 138.326721 86.0561555 134.699592 30.3619927 104"></polygon>
                    <polygon
                      id="Path-4-Copy-49"
                      points="33 102.251178 85.1232505 131.326721 97.0561555 128.699592 41.3619927 98"></polygon>
                    <polygon
                      id="Path-4-Copy-53"
                      points="-1.0382307e-12 120.251178 52.1232505 150.326721 64.0561555 146.699592 8.3619927 116"></polygon>
                    <polygon
                      id="Path-4-Copy-52"
                      points="11 114.251178 63.1232505 143.326721 75.0561555 140.699592 19.3619927 110"></polygon>
                    <polygon
                      id="Path-4-Copy-48"
                      points="44 96.2511783 96.1232505 125.326721 108.056156 122.699592 52.3619927 92"></polygon>
                    <polygon
                      id="Path-4-Copy-47"
                      points="56 90.2511783 108.123251 119.326721 120.056156 116.699592 64.3619927 86"></polygon>
                    <polygon
                      id="Path-4-Copy-46"
                      points="67 84.2511783 119.123251 113.326721 131.056156 110.699592 75.3619927 80"></polygon>
                  </g>
                </g>
              </g>
              <g id="output-n" transform="translate(318.000000, 159.000000)">
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3"></use>
                </mask>
                <use
                  id="output-n-mask"
                  fillOpacity="0.08"
                  fill="#FFFFFF"
                  xlinkHref="#path-3"></use>
                <g
                  id="bars-output-n1"
                  opacity="0.5"
                  mask="url(#mask-4)"
                  fill="#FFFFFF">
                  <g transform="translate(-25.000000, -11.000000)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="215.294624 4.25117832 267.417874 33.3267215 279.350779 30.699592 223.656616 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="158.294624 34.2511783 210.417874 64.3267215 222.350779 60.699592 166.656616 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="169.294624 28.2511783 221.417874 57.3267215 233.350779 54.699592 177.656616 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="180.294624 22.2511783 232.417874 51.3267215 244.350779 48.699592 188.656616 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="192.294624 16.2511783 244.417874 45.3267215 256.350779 42.699592 200.656616 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="203.294624 10.2511783 255.417874 39.3267215 267.350779 36.699592 211.656616 6"></polygon>
                    <polygon
                      id="Path-4-Copy-45"
                      points="147 41.2511783 199.123251 70.3267215 211.056156 67.699592 155.361993 37"></polygon>
                    <polygon
                      id="Path-4-Copy-44"
                      points="90 71.2511783 142.123251 101.326721 154.056156 97.699592 98.3619927 67"></polygon>
                    <polygon
                      id="Path-4-Copy-43"
                      points="101 65.2511783 153.123251 94.3267215 165.056156 91.699592 109.361993 61"></polygon>
                    <polygon
                      id="Path-4-Copy-42"
                      points="112 59.2511783 164.123251 88.3267215 176.056156 85.699592 120.361993 55"></polygon>
                    <polygon
                      id="Path-4-Copy-41"
                      points="124 53.2511783 176.123251 82.3267215 188.056156 79.699592 132.361993 49"></polygon>
                    <polygon
                      id="Path-4-Copy-40"
                      points="135 47.2511783 187.123251 76.3267215 199.056156 73.699592 143.361993 43"></polygon>
                    <polygon
                      id="Path-4-Copy-51"
                      points="79 78.2511783 131.123251 107.326721 143.056156 104.699592 87.3619927 74"></polygon>
                    <polygon
                      id="Path-4-Copy-50"
                      points="22 108.251178 74.1232505 138.326721 86.0561555 134.699592 30.3619927 104"></polygon>
                    <polygon
                      id="Path-4-Copy-49"
                      points="33 102.251178 85.1232505 131.326721 97.0561555 128.699592 41.3619927 98"></polygon>
                    <polygon
                      id="Path-4-Copy-53"
                      points="-1.0382307e-12 120.251178 52.1232505 150.326721 64.0561555 146.699592 8.3619927 116"></polygon>
                    <polygon
                      id="Path-4-Copy-52"
                      points="11 114.251178 63.1232505 143.326721 75.0561555 140.699592 19.3619927 110"></polygon>
                    <polygon
                      id="Path-4-Copy-48"
                      points="44 96.2511783 96.1232505 125.326721 108.056156 122.699592 52.3619927 92"></polygon>
                    <polygon
                      id="Path-4-Copy-47"
                      points="56 90.2511783 108.123251 119.326721 120.056156 116.699592 64.3619927 86"></polygon>
                    <polygon
                      id="Path-4-Copy-46"
                      points="67 84.2511783 119.123251 113.326721 131.056156 110.699592 75.3619927 80"></polygon>
                  </g>
                </g>
                <g
                  id="bars-output-n2"
                  opacity="0.5"
                  mask="url(#mask-4)"
                  fill="#FFFFFF">
                  <g transform="translate(-251.000000, 111.000000)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="215.294624 4.25117832 267.417874 33.3267215 279.350779 30.699592 223.656616 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="158.294624 34.2511783 210.417874 64.3267215 222.350779 60.699592 166.656616 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="169.294624 28.2511783 221.417874 57.3267215 233.350779 54.699592 177.656616 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="180.294624 22.2511783 232.417874 51.3267215 244.350779 48.699592 188.656616 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="192.294624 16.2511783 244.417874 45.3267215 256.350779 42.699592 200.656616 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="203.294624 10.2511783 255.417874 39.3267215 267.350779 36.699592 211.656616 6"></polygon>
                    <polygon
                      id="Path-4-Copy-45"
                      points="147 41.2511783 199.123251 70.3267215 211.056156 67.699592 155.361993 37"></polygon>
                    <polygon
                      id="Path-4-Copy-44"
                      points="90 71.2511783 142.123251 101.326721 154.056156 97.699592 98.3619927 67"></polygon>
                    <polygon
                      id="Path-4-Copy-43"
                      points="101 65.2511783 153.123251 94.3267215 165.056156 91.699592 109.361993 61"></polygon>
                    <polygon
                      id="Path-4-Copy-42"
                      points="112 59.2511783 164.123251 88.3267215 176.056156 85.699592 120.361993 55"></polygon>
                    <polygon
                      id="Path-4-Copy-41"
                      points="124 53.2511783 176.123251 82.3267215 188.056156 79.699592 132.361993 49"></polygon>
                    <polygon
                      id="Path-4-Copy-40"
                      points="135 47.2511783 187.123251 76.3267215 199.056156 73.699592 143.361993 43"></polygon>
                    <polygon
                      id="Path-4-Copy-51"
                      points="79 78.2511783 131.123251 107.326721 143.056156 104.699592 87.3619927 74"></polygon>
                    <polygon
                      id="Path-4-Copy-50"
                      points="22 108.251178 74.1232505 138.326721 86.0561555 134.699592 30.3619927 104"></polygon>
                    <polygon
                      id="Path-4-Copy-49"
                      points="33 102.251178 85.1232505 131.326721 97.0561555 128.699592 41.3619927 98"></polygon>
                    <polygon
                      id="Path-4-Copy-53"
                      points="-1.0382307e-12 120.251178 52.1232505 150.326721 64.0561555 146.699592 8.3619927 116"></polygon>
                    <polygon
                      id="Path-4-Copy-52"
                      points="11 114.251178 63.1232505 143.326721 75.0561555 140.699592 19.3619927 110"></polygon>
                    <polygon
                      id="Path-4-Copy-48"
                      points="44 96.2511783 96.1232505 125.326721 108.056156 122.699592 52.3619927 92"></polygon>
                    <polygon
                      id="Path-4-Copy-47"
                      points="56 90.2511783 108.123251 119.326721 120.056156 116.699592 64.3619927 86"></polygon>
                    <polygon
                      id="Path-4-Copy-46"
                      points="67 84.2511783 119.123251 113.326721 131.056156 110.699592 75.3619927 80"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="input-s"
                opacity="0.5"
                transform="translate(261.000000, 406.624756)">
                <mask id="mask-6" fill="white">
                  <use xlinkHref="#path-5"></use>
                </mask>
                <g id="input-3smask"></g>
                <g id="bars-input-s1" mask="url(#mask-6)" fill="#FFFFFF">
                  <g transform="translate(-3.000000, -26.624756)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="80.2946236 4.25117832 156.95124 48 168.884145 45.3728705 88.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="23.2946236 34.2511783 99.9512399 79 111.884145 75.3728705 31.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="34.2946236 28.2511783 110.95124 72 122.884145 69.3728705 42.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="45.2946236 22.2511783 121.95124 66 133.884145 63.3728705 53.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="57.2946236 16.2511783 133.95124 60 145.884145 57.3728705 65.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="68.2946236 10.2511783 144.95124 54 156.884145 51.3728705 76.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-45"
                      points="12 41.2511783 88.6566163 85 100.589521 82.3728705 20.3619927 37"></polygon>
                    <polygon
                      id="Path-4-Copy-40"
                      points="-1.6635083e-12 47.2511783 76.6566163 91 88.5895213 88.3728705 8.3619927 43"></polygon>
                  </g>
                </g>
                <g id="bars-input-s2" mask="url(#mask-6)" fill="#FFFFFF">
                  <g transform="translate(-94.000000, 22.875244)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="80.2946236 4.25117832 156.95124 48 168.884145 45.3728705 88.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="23.2946236 34.2511783 99.9512399 79 111.884145 75.3728705 31.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="34.2946236 28.2511783 110.95124 72 122.884145 69.3728705 42.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="45.2946236 22.2511783 121.95124 66 133.884145 63.3728705 53.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="57.2946236 16.2511783 133.95124 60 145.884145 57.3728705 65.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="68.2946236 10.2511783 144.95124 54 156.884145 51.3728705 76.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-45"
                      points="12 41.2511783 88.6566163 85 100.589521 82.3728705 20.3619927 37"></polygon>
                    <polygon
                      id="Path-4-Copy-40"
                      points="-1.6635083e-12 47.2511783 76.6566163 91 88.5895213 88.3728705 8.3619927 43"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="output-s"
                opacity="0.5"
                transform="translate(543.000000, 244.000000)">
                <mask id="mask-8" fill="white">
                  <use xlinkHref="#path-7"></use>
                </mask>
                <g id="output-3smask"></g>
                <g id="bars-output-s1" mask="url(#mask-8)" fill="#FFFFFF">
                  <g transform="translate(-3.000000, -26.624756)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="80.2946236 4.25117832 156.95124 48 168.884145 45.3728705 88.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="23.2946236 34.2511783 99.9512399 79 111.884145 75.3728705 31.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="34.2946236 28.2511783 110.95124 72 122.884145 69.3728705 42.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="45.2946236 22.2511783 121.95124 66 133.884145 63.3728705 53.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="57.2946236 16.2511783 133.95124 60 145.884145 57.3728705 65.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="68.2946236 10.2511783 144.95124 54 156.884145 51.3728705 76.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-45"
                      points="12 41.2511783 88.6566163 85 100.589521 82.3728705 20.3619927 37"></polygon>
                    <polygon
                      id="Path-4-Copy-40"
                      points="-1.6635083e-12 47.2511783 76.6566163 91 88.5895213 88.3728705 8.3619927 43"></polygon>
                  </g>
                </g>
                <g id="bars-output-s2" mask="url(#mask-8)" fill="#FFFFFF">
                  <g transform="translate(-94.000000, 22.875244)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="80.2946236 4.25117832 156.95124 48 168.884145 45.3728705 88.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="23.2946236 34.2511783 99.9512399 79 111.884145 75.3728705 31.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="34.2946236 28.2511783 110.95124 72 122.884145 69.3728705 42.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="45.2946236 22.2511783 121.95124 66 133.884145 63.3728705 53.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="57.2946236 16.2511783 133.95124 60 145.884145 57.3728705 65.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="68.2946236 10.2511783 144.95124 54 156.884145 51.3728705 76.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-45"
                      points="12 41.2511783 88.6566163 85 100.589521 82.3728705 20.3619927 37"></polygon>
                    <polygon
                      id="Path-4-Copy-40"
                      points="-1.6635083e-12 47.2511783 76.6566163 91 88.5895213 88.3728705 8.3619927 43"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="input-n"
                opacity="0.5"
                transform="translate(70.000000, 323.000000)">
                <mask id="mask-10" fill="white">
                  <use xlinkHref="#path-9"></use>
                </mask>
                <g id="input-n-mask"></g>
                <g id="bars-input-n1" mask="url(#mask-10)" fill="#FFFFFF">
                  <g transform="translate(91.000000, 0.000000)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="80.2946236 4.25117832 156.95124 48 168.884145 45.3728705 88.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="23.2946236 34.2511783 99.9512399 79 111.884145 75.3728705 31.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="34.2946236 28.2511783 110.95124 72 122.884145 69.3728705 42.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="45.2946236 22.2511783 121.95124 66 133.884145 63.3728705 53.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="57.2946236 16.2511783 133.95124 60 145.884145 57.3728705 65.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="68.2946236 10.2511783 144.95124 54 156.884145 51.3728705 76.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-45"
                      points="12 41.2511783 88.6566163 85 100.589521 82.3728705 20.3619927 37"></polygon>
                    <polygon
                      id="Path-4-Copy-40"
                      points="-1.6635083e-12 47.2511783 76.6566163 91 88.5895213 88.3728705 8.3619927 43"></polygon>
                  </g>
                </g>
                <g id="bars-input-n2" mask="url(#mask-10)" fill="#FFFFFF">
                  <g transform="translate(0.000000, 49.500000)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="80.2946236 4.25117832 156.95124 48 168.884145 45.3728705 88.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="23.2946236 34.2511783 99.9512399 79 111.884145 75.3728705 31.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="34.2946236 28.2511783 110.95124 72 122.884145 69.3728705 42.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="45.2946236 22.2511783 121.95124 66 133.884145 63.3728705 53.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="57.2946236 16.2511783 133.95124 60 145.884145 57.3728705 65.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="68.2946236 10.2511783 144.95124 54 156.884145 51.3728705 76.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-45"
                      points="12 41.2511783 88.6566163 85 100.589521 82.3728705 20.3619927 37"></polygon>
                    <polygon
                      id="Path-4-Copy-40"
                      points="-1.6635083e-12 47.2511783 76.6566163 91 88.5895213 88.3728705 8.3619927 43"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="output-m"
                opacity="0.5"
                transform="translate(373.000000, 171.000000)">
                <mask id="mask-12" fill="white">
                  <use xlinkHref="#path-11"></use>
                </mask>
                <g id="output-m-mask"></g>
                <g id="bars-output-m1" mask="url(#mask-12)" fill="#FFFFFF">
                  <g transform="translate(91.000000, 0.000000)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="80.2946236 4.25117832 156.95124 48 168.884145 45.3728705 88.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="23.2946236 34.2511783 99.9512399 79 111.884145 75.3728705 31.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="34.2946236 28.2511783 110.95124 72 122.884145 69.3728705 42.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="45.2946236 22.2511783 121.95124 66 133.884145 63.3728705 53.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="57.2946236 16.2511783 133.95124 60 145.884145 57.3728705 65.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="68.2946236 10.2511783 144.95124 54 156.884145 51.3728705 76.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-45"
                      points="12 41.2511783 88.6566163 85 100.589521 82.3728705 20.3619927 37"></polygon>
                    <polygon
                      id="Path-4-Copy-40"
                      points="-1.6635083e-12 47.2511783 76.6566163 91 88.5895213 88.3728705 8.3619927 43"></polygon>
                  </g>
                </g>
                <g id="bars-output-m2" mask="url(#mask-12)" fill="#FFFFFF">
                  <g transform="translate(0.000000, 49.500000)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="80.2946236 4.25117832 156.95124 48 168.884145 45.3728705 88.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="23.2946236 34.2511783 99.9512399 79 111.884145 75.3728705 31.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="34.2946236 28.2511783 110.95124 72 122.884145 69.3728705 42.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="45.2946236 22.2511783 121.95124 66 133.884145 63.3728705 53.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="57.2946236 16.2511783 133.95124 60 145.884145 57.3728705 65.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="68.2946236 10.2511783 144.95124 54 156.884145 51.3728705 76.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-45"
                      points="12 41.2511783 88.6566163 85 100.589521 82.3728705 20.3619927 37"></polygon>
                    <polygon
                      id="Path-4-Copy-40"
                      points="-1.6635083e-12 47.2511783 76.6566163 91 88.5895213 88.3728705 8.3619927 43"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="mid-s"
                opacity="0.5"
                transform="translate(448.000000, 309.000000)">
                <mask id="mask-14" fill="white">
                  <use xlinkHref="#path-13"></use>
                </mask>
                <g id="mid-s-mask"></g>
                <g id="bars-mid-s1" mask="url(#mask-14)" fill="#FFFFFF">
                  <g transform="translate(-55.000000, -9.000000)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="80.2946236 4.25117832 156.95124 48 168.884145 45.3728705 88.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="23.2946236 34.2511783 99.9512399 79 111.884145 75.3728705 31.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="34.2946236 28.2511783 110.95124 72 122.884145 69.3728705 42.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="45.2946236 22.2511783 121.95124 66 133.884145 63.3728705 53.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="57.2946236 16.2511783 133.95124 60 145.884145 57.3728705 65.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="68.2946236 10.2511783 144.95124 54 156.884145 51.3728705 76.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-45"
                      points="12 41.2511783 88.6566163 85 100.589521 82.3728705 20.3619927 37"></polygon>
                    <polygon
                      id="Path-4-Copy-40"
                      points="-1.6635083e-12 47.2511783 76.6566163 91 88.5895213 88.3728705 8.3619927 43"></polygon>
                  </g>
                </g>
                <g id="bars-mid-s2" mask="url(#mask-14)" fill="#FFFFFF">
                  <g transform="translate(-146.000000, 40.500000)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="80.2946236 4.25117832 156.95124 48 168.884145 45.3728705 88.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="23.2946236 34.2511783 99.9512399 79 111.884145 75.3728705 31.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="34.2946236 28.2511783 110.95124 72 122.884145 69.3728705 42.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="45.2946236 22.2511783 121.95124 66 133.884145 63.3728705 53.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="57.2946236 16.2511783 133.95124 60 145.884145 57.3728705 65.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="68.2946236 10.2511783 144.95124 54 156.884145 51.3728705 76.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-45"
                      points="12 41.2511783 88.6566163 85 100.589521 82.3728705 20.3619927 37"></polygon>
                    <polygon
                      id="Path-4-Copy-40"
                      points="-1.6635083e-12 47.2511783 76.6566163 91 88.5895213 88.3728705 8.3619927 43"></polygon>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <img
        id="img"
        src="/img/solutions/skg-hero.png"
        alt="Secure Kubernetes Gateway"
      />
    </div>
  )
}

export default SkgHeroAnim
