import React, { useState } from "react"
import styles from "../../../styles/solutions.module.scss"
import cblockStyles from "../../../styles/commons/cblock.module.scss"
import FsLightbox from "fslightbox-react"
import Layout from "../../../components/layout/layout"
import StartDemo from "../../../components/start-demo/start-demo"
import SEO from "../../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import withAppContext from "../../../contexts/app.context"
import UseCaseOverview from "../../../components/use-case-overview/use-case-overview"
import { Helper } from "../../../utils/helper"
import { ENV_CONSOLE_BASE_URL } from "../../../utils/secrets"
import SkgHeroAnim from "../../../components/animated-graphs/skg-hero/skg-hero-anim"
import TopFeatures from "../../../components/top-features/top-features"
import ComparisonTable from "../../../components/comparison-table/comparison-table"
import CascadeVideo from "../../../components/cascade-video/cascade-video"
import PlaySvg from "../../../components/svg/play"
import ArrowSvg from "../../../components/svg/arrow"

const KubernetesGatewayPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "solutions__kubernetes-gateway" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const demoLink = "https://player.vimeo.com/video/409061269?autoplay=1"
  const overviewLink = "https://player.vimeo.com/video/409046389?autoplay=1"

  const [isVideoOpened, setVideoOpened] = useState(false)
  const [currentVideoUrl, setCurrentVideoUrl] = useState(overviewLink)

  const openSelectedVideo = () => {
    Helper.triggerGtagVideo(currentVideoUrl)
    setCurrentVideoUrl(overviewLink)
    setVideoOpened(!isVideoOpened)
  }

  const openDemoVideo = () => {
    Helper.triggerGtagVideo(currentVideoUrl)
    setCurrentVideoUrl(demoLink)
    setVideoOpened(!isVideoOpened)
  }

  const [selectedTopFeatIndex, setselectedTopFeatIndex] = useState(1)

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/solutions/multi-cloud/secure-kubernetes-gateway"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      {/* Hero Block */}
      <section className={styles.header}>
        <div className={styles.header__content}>
          <div className="container">
            <div className="row">
              <div className={`col-lg-7`}>
                <h1 className={styles.header__title}>
                  Secure Kubernetes Gateway
                </h1>
                <p className={styles.header__description}>
                  VoltMesh is a next-gen K8s ingress-egress controller that
                  integrates a load balancer, API gateway and multi-layer
                  security. It delivers significant savings through service
                  integration, automation and <nobr>SaaS-based</nobr> operation.
                </p>
                <div className={styles.header__cta}>
                  <a
                    aria-label="Start for Free"
                    href={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                    className={"btn btn-primary " + styles.header__btn}>
                    Start for Free
                  </a>
                  <button
                    onClick={openDemoVideo}
                    className={`btn btn-primary ml-4 ${styles.header__btn} ${styles.header__btnPlay}`}>
                    <PlaySvg />
                    Watch Demo
                  </button>
                </div>
                <div className={styles.header__doc}>
                  <p>
                    <a
                      aria-label="View demo documentation"
                      className={styles.header__link}
                      href="/docs/quick-start/secure-kubernetes-gateway">
                      View demo documentation
                      <ArrowSvg />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.header__imgwrapper}>
            <button
              className={styles.header__imgbtn}
              onClick={openSelectedVideo}>
              <PlaySvg />
            </button>
            <SkgHeroAnim />
          </div>
        </div>
      </section>

      {/* Benefits */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Benefits of VoltMesh for <br />
              Kubernetes Ingress & Egress
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <TopFeatures
              props={{
                features: [
                  {
                    title: "Faster deployment & simplified ops",
                    desc:
                      "DevOps and developers can significantly simplify deployment operations of one or more K8s ingress and egress controllers with our SaaS-based lifecycle management and multi-cluster control plane.",
                    imgUrl: "/img/solutions/faster-deployments.svg",
                    imgAlt: "Cloud deployments illustration",
                  },
                  {
                    title: "Maximum security & zero-trust",
                    desc: `Implement multi-layer security in and across clusters, including ingress + egress, WAF and DDoS mitigation. Automate zero-trust at the API-level with <a href="/products/voltmesh-automated-zero-trust-and-api-security" target="_blank">API discovery</a> and policy-based whitelisting.`,
                    imgUrl: "/img/solutions/maximum-security.svg",
                    imgAlt: "Firewall protecting cloud deployment illustration",
                  },
                  {
                    title: "Increased uptime & reliability",
                    desc:
                      "Deliver highly available services across clusters or clouds. Use our global network to connect across clusters and expose services to the Internet - with built-in L3-L7 DDoS mitigation, WAF, DNS, and TLS certificate management.",
                    imgUrl: "/img/solutions/increased-uptime.svg",
                    imgAlt: "Healthy cloud deployments illustration",
                  },
                  {
                    title: "Improve developer experience",
                    desc:
                      "Increase productivity by delivering APIs without VPNs or complex firewall configs.  Give simple and secure access to backend services to accelerate testing, and the ability to expose services for inbound testing.",
                    imgUrl: "/img/solutions/improved-dev-experience.svg",
                    imgAlt: "Simplified developer API access illustration",
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* Comparison Table */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              VoltMesh vs. Traditional Ingress Controllers
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <ComparisonTable
              props={{
                cols: ["Features", "Traditional", "VoltMesh"],
                rows: [
                  {
                    name: "API gateway",
                    cells: ["Included", "Included"],
                  },
                  {
                    name: "Multi-cluster control plane",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Automated DNS + TLS certificates",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "NG-WAF & egress security",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Multi-cloud backbone",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "End-to-end observability & audit logs",
                    cells: ["External", "Included"],
                  },
                  {
                    name: "Lifecycle management",
                    cells: ["Controller", "SaaS"],
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* Solution Overview */}
      <UseCaseOverview
        props={{
          content: {
            description: `
            <p>VoltMesh provides a secure Kubernetes ingress and egress controller with a comprehensive set of load balancing, API gateway and multi-layer security capabilities to “connect, secure, and observe” one or multiple Kubernetes clusters. It is automatically deployed via VoltConsole into any K8s cluster with automated software lifecycle management, unified policy enforcement, and aggregated metrics and logs for observability.</p>
            <p>Teams have the option to use the Volterra global network for secure, high-performance connectivity across multiple K8s clusters and clouds. It allows you to directly expose select services to the public Internet, perform global traffic management, and get robust DDoS and WAF protection without using additional service providers.</p>`,
            diagram: {
              alt: "Network & Security diagram",
              name: "secure-kubernetes-diagram.png",
              width: 840,
              md: {
                name: "secure-kubernetes-diagram-md.png",
                width: 760,
              },
              sm: {
                name: "secure-kubernetes-diagram-sm.png",
                width: 460,
              },
            },

            capabilities: {
              left: [
                "API gateway",
                "Global traffic management",
                "API auto-discovery & control",
                "Load balancer & service mesh",
                "NG-WAF, DDoS, egress security",
                "End-to-end observability",
              ],
            },
          },
        }}
      />

      {/* Ready in minutes */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__bubbles__vmtlmr} ${cblockStyles.cblock__grey}`}>
        <div className={"container"}>
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Connect K8s Services within Minutes
            </h2>
          </div>

          <div className={cblockStyles.cblock__body}>
            <div className={"row " + styles.topfeatsslider}>
              <div className="col-lg-5">
                <div className={styles.topfeatsslider__items}>
                  {/* Item 1 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(1)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 1
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>1</span>
                      <h5>
                        Deploy VoltMesh in Public&nbsp;
                        <span>
                          Cloud
                          <img
                            className={`ml-2 ${styles.topfeatsslider__caret}`}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Fully automated provisioning of VoltMesh using the UI or
                        our vesctl CLI - deploy as a VM in your cloud VPC or add
                        as K8s Pod in your cluster
                      </p>
                      <a
                        href="/docs/quick-start/secure-kubernetes-gateway#step-1-deploy--secure-site"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 1: Deploy & Secure Site">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 1 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462830283.sd.mp4?s=b2ed51267ebcd12cb6d245f60971064f0938fc2f&profile_id=165",
                                  videoLength: "11000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462830387.sd.mp4?s=eb9394f64b19c3188ea4630aefaa6d869b4c68f1&profile_id=165",
                                  videoLength: "16000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 2 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(2)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 2
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>2</span>
                      <h5>
                        Delegate&nbsp;
                        <span>
                          Domain
                          <img
                            className={styles.topfeatsslider__caret}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Delegate a domain or subdomain to manage creation of
                        public DNS entries and automated provisioning of TLS
                        certificates
                      </p>
                      <a
                        href="/docs/quick-start/secure-kubernetes-gateway#step-2-discover--delegate"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 2: Discover & Delegate">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 2 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462830268.sd.mp4?s=b98c8e806d1a1b123d88e8070b8edecbc63a293f&profile_id=165",
                                  videoLength: "15000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462830249.sd.mp4?s=f6b97b3e3398c7ed7d8259eb4f231c5d2a0bffd2&profile_id=165",
                                  videoLength: "27000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 3 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(3)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 3
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>3</span>
                      <h5>
                        Discover & Route&nbsp;
                        <span>
                          Services
                          <img
                            className={`ml-2 ${styles.topfeatsslider__caret}`}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Configure discovery of K8s services and implement
                        routing + policy rules to direct incoming requests to
                        discovered services
                      </p>
                      <a
                        href="/docs/quick-start/secure-kubernetes-gateway#step-3-load-balancer"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 3: Load Balancer">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 3 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462830327.sd.mp4?s=24765170bb477575212106442f88736d18a0e6b4&profile_id=165",
                                  videoLength: "37000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462830294.sd.mp4?s=5a87cf1a662d35435adb31f12a1913587ca04da2&profile_id=165",
                                  videoLength: "33000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 4 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(4)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 4
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>4</span>
                      <h5>
                        Send&nbsp;
                        <span>
                          Requests
                          <img
                            className={styles.topfeatsslider__caret}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Send requests to the specified service or APIs and let
                        VoltMesh handle routing of the requests to the right
                        cluster and endpoint
                      </p>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 4 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462830355.sd.mp4?s=ac92df043981a347c27afec65393705038218112&profile_id=165",
                                  videoLength: "33000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462830340.sd.mp4?s=5ccf2e7d9294a8ce3e448732410c8723a8d0b210&profile_id=165",
                                  videoLength: "14000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className={styles.topfeatsslider__img}>
                  {selectedTopFeatIndex === 1 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462830283.sd.mp4?s=b2ed51267ebcd12cb6d245f60971064f0938fc2f&profile_id=165",
                              videoLength: "11000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/462830387.sd.mp4?s=eb9394f64b19c3188ea4630aefaa6d869b4c68f1&profile_id=165",
                              videoLength: "16000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 2 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462830268.sd.mp4?s=b98c8e806d1a1b123d88e8070b8edecbc63a293f&profile_id=165",
                              videoLength: "15000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/462830249.sd.mp4?s=f6b97b3e3398c7ed7d8259eb4f231c5d2a0bffd2&profile_id=165",
                              videoLength: "27000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 3 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462830327.sd.mp4?s=24765170bb477575212106442f88736d18a0e6b4&profile_id=165",
                              videoLength: "37000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/462830294.sd.mp4?s=5a87cf1a662d35435adb31f12a1913587ca04da2&profile_id=165",
                              videoLength: "33000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 4 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462830355.sd.mp4?s=ac92df043981a347c27afec65393705038218112&profile_id=165",
                              videoLength: "33000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/462830340.sd.mp4?s=5ccf2e7d9294a8ce3e448732410c8723a8d0b210&profile_id=165",
                              videoLength: "14000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <StartDemo
        props={{
          benefits: [
            "K8s ingress & egress",
            "Multi-cluster service mesh",
            "API gateway",
            "API auto-discovery & control",
          ],
        }}
      />

      <FsLightbox
        toggler={isVideoOpened}
        sources={[
          <iframe
            title="Video player"
            src={currentVideoUrl}
            width="1920px"
            height="1080px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
        key="1"
      />
    </Layout>
  )
}

export default withAppContext(KubernetesGatewayPage)
